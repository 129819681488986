import {createWebHistory, createRouter} from "vue-router";

export const routes = [
    //FOR ALL
    {
        name: 'home',
        path: '/',
        meta: {
            layout: 'empty',
            title: 'home',
            breadcrumbs: ['home'],
            darkMode: false
        },
        component: () => import('../pages/Home.vue')
    },
    {
        name: 'register',
        path: '/register',
        meta: {
            layout: 'main',
            title: 'register',
            breadcrumbs: ['home']
        },
        component: () => import('../pages/Register.vue')

    },

    //DASHBOARD
    {
        name: 'dashboard',
        path: '/dashboard',
        meta: {
            layout: 'adminpanel',
            title: 'dashboard'
        },
        component: () => import('../pages/Dashboard.vue')
    },
    {
        name: 'dashboard.contacts',
        path: '/dashboard/contacts',
        meta: {
            layout: 'adminpanel',
            title: 'contacts',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Contactsdb.vue')
    },


    {
        name: 'dashboard.reservations',
        path: '/dashboard/reservations',
        meta: {
            layout: 'adminpanel',
            title: 'reservations',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Reservations.vue')
    },
    {
        name: 'dashboard.tourists',
        path: '/dashboard/tourists',
        meta: {
            layout: 'adminpanel',
            title: 'tourists',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Tourists.vue')
    },
    {
        name: 'dashboard.payments',
        path: '/dashboard/payments',
        meta: {
            layout: 'adminpanel',
            title: 'payments',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Payments.vue')
    },
    {
        name: 'dashboard.pricenegotiation',
        path: '/dashboard/pricenegotiation',
        meta: {
            layout: 'adminpanel',
            title: 'price_negotiation',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Pricenegotiation.vue')
    },
    {
        name: 'dashboard.hotels',
        path: '/dashboard/hotels/',
        meta: {
            layout: 'adminpanel',
            title: 'hotels',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Hotels.vue')
    },
    {
        name: 'dashboard.contracts',
        path: '/dashboard/contracts',
        meta: {
            layout: 'adminpanel',
            title: 'contracts',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Contracts.vue')
    },
    {
        name: 'dashboard.prices',
        path: '/dashboard/prices',
        meta: {
            layout: 'adminpanel',
            title: 'prices',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Prices.vue')
    },
    {
        name: 'dashboard.allocation',
        path: '/dashboard/allocation',
        meta: {
            layout: 'adminpanel',
            title: 'allocation',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Allocation.vue')
    },
    {
        name: 'dashboard.stopsale',
        path: '/dashboard/stopsale',
        meta: {
            layout: 'adminpanel',
            title: 'stop_sale',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Stopsale.vue')
    },
    {
        name: 'dashboard.subscription',
        path: '/dashboard/subscription',
        meta: {
            layout: 'adminpanel',
            title: 'subscription',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Subscription.vue')
    },
    {
        name: 'dashboard.messages',
        path: '/dashboard/messages',
        meta: {
            layout: 'adminpanel',
            title: 'messages',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Messages.vue')
    },

    {
        name: 'dashboard.devexamples',
        path: '/dashboard/devexamples',
        meta: {
            layout: 'adminpanel',
            title: 'devexamples',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Devexamples.vue')
    },
    {
        name: 'dashboard.devexamples.dashboard1',
        path: '/dashboard/devexamples/dashboard1',
        meta: {
            layout: 'adminpanel',
            title: 'dashboard1',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Dashboard1.vue')
    },
    {
        name: 'dashboard.devexamples.dashboard2',
        path: '/dashboard/devexamples/dashboard2',
        meta: {
            layout: 'adminpanel',
            title: 'dashboard2',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Dashboard2.vue')
    },
    {
        name: 'dashboard.devexamples.testapi',
        path: '/dashboard/devexamples/testapi',
        meta: {
            layout: 'adminpanel',
            title: 'test_api',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/Testapi.vue')
    },
    {
        name: 'dashboard.user',
        path: '/dashboard/user',
        meta: {
            layout: 'adminpanel',
            title: 'user',
            breadcrumbs: ['dashboard']
        },
        component: () => import('../pages/User.vue')
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router
