import User from '@/models/User'
import apiService from '@/services/api.service'
import { useToast } from "vue-toastification";

const toast = useToast();

export default {

    async login (credentials) {
        return new Promise((resolve, reject) => {
            apiService.getFrApi({
                url: 'login',
                method: 'post',
                params: credentials
            }).then(response => {
                if (response.data.user){
                    const curUser = new User(response.data.user)
                    if (curUser.isAuthUser()) {
                        resolve(curUser)
                    } else {
                        reject('no_user_found') 
                    }
                } else {
                    reject('no_user_found')    
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async logout () {
        return new Promise((resolve, reject) => {
            apiService.getFrApi({
                url: 'logout'
            }).then(response => {
                resolve('logout_success')
            }).catch((error) => {
                reject('logout_error')
            })
        })
    },

    async getUserBySession () {
        return new Promise((resolve, reject) => {
            apiService.getFrApi({
                url: 'getuser'
            }).then(response => {
                if (response.data.user){
                    const curUser = new User(response.data.user)
                    if (curUser.isAuthUser()) {
                        resolve(curUser)
                    } else {
                        reject('no_user_found') 
                    }
                } else {
                    reject('no_user_found')    
                }
            }).catch((error) => {
                reject(error)
            })
        })
    },

    async getUserByToken (token) {
        return new Promise((resolve, reject) => {
            apiService.getFrApi({
                url: 'getuser',
                token: token
            }).then(response => {
                if (response.data.user){
                    const curUser = new User(response.data.user)
                    if (curUser.isAuthUser()) {
                        resolve(curUser)
                    } else {
                        reject('no_user_found') 
                    }
                } else {
                    reject('no_user_found')    
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }


}
