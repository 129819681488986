<template>
    <div class="wrapper adminpanel-layout">

        <!-- Navbar -->
        <nav class="nav main-header navbar navbar-expand"
             :class="{'bg-dark': darkMode, 'navbar-dark': darkMode, 'border-bottom-0': darkMode, 'navbar-white': !darkMode, 'navbar-light': !darkMode }">
            <!-- Left navbar links -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <toggle-sidebar />
                </li>
                <li class="nav-item d-none d-sm-inline-block">
                    <router-link to="/dashboard"  class="nav-link">{{ $t('route_headers.home') }}</router-link>
                </li>
                <li class="nav-item d-none d-sm-inline-block">
                    <router-link to="/dashboard/contacts"  class="nav-link">{{ $t('route_headers.contacts') }}</router-link>
                </li>
            </ul>

            <!-- Right navbar links -->
            <ul class="navbar-nav mx-auto justify-content-end w-100">

                <li class="nav-item">
                    <messages />
                </li>

                <li class="nav-item">
                    <day-night-switcher />
                </li>

                <li class="nav-item">
                    <fullscreen />
                </li>

                <li class="nav-item">
                    <Swlang/>
                </li>

            </ul>
        </nav>
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar elevation-4" :class="{'sidebar-dark-primary': darkMode, 'sidebar-light-primary': !darkMode}">
            <!-- Brand Logo -->
            <a href="/dashboard" class="brand-link">
                <img src="/img/office.svg" alt="Hotelgate" class="logo">
                <span class="brand-text font-weight-light">Hotel Office</span>
            </a>

            <!-- Sidebar -->
            <div class="sidebar">
                <!-- Sidebar user panel (optional) -->
              <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <router-link :to="{ name: 'dashboard.user' }" custom v-slot="{ href, navigate }">
                  <div class="image" @click="navigate">
                    <img src="/img/alte/avatar2.png" class="img-circle elevation-2" alt="User Image">
                  </div>
                </router-link>
                <router-link :to="{ name: 'dashboard.user' }" custom v-slot="{ href, navigate }">
                  <div class="info" @click="navigate">
                    <a href="#" class="d-block">{{ user.name }}</a>
                  </div>
                </router-link>
              </div>

                <!-- Sidebar Menu -->
                <Sidebar/>
                <!-- /.sidebar-menu -->

            </div>
            <!-- /.sidebar -->
        </aside>

        <div id="sidebar-overlay" @click="sidebarClose"></div>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <Pagetitle/>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <Breadcrumbs/>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <div class="content">
            <div class="container-fluid">
                <router-view />
            </div>
        </div>
        <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->

        <!-- Main Footer -->
        <footer class="main-footer">
            <!-- Default to the left -->
            {{ currentDate }}
            <strong>Copyright © 2023 SAMO-Soft.</strong> All rights reserved.

            <div class="float-end d-none d-sm-block">
                <b>Version</b> 0.1.0
            </div>
        </footer>
    </div>
    <!-- ./wrapper -->
</template>

<script>
// import { computed } from 'vue'
import { useStore, mapState } from 'vuex'

import ControlSidebar from '@/alte/ControlSidebar'
import Layout from '@/alte/Layout'
import IFrame from '@/alte/IFrame'
import PushMenu from '@/alte/PushMenu'
import Treeview from '@/alte/Treeview'
import ExpandableTable from '@/alte/ExpandableTable'

import ToggleSidebar from "@/components/app/ToggleSidebar";
import Sidebar from "@/components/app/Sidebar";
import Messages from "@/components/app/Messages";
import Fullscreen from "@/components/app/Fullscreen";
import Swlang from "@/components/app/Swlang";
import DayNightSwitcher from "@/components/app/DayNightSwitcher";
import Pagetitle from "@/components/app/Pagetitle";
import Breadcrumbs from "@/components/app/Breadcrumbs";

export default {
    name: 'adminpanel-layout',
    computed: {
        currentDate() {
            const date = new Date();
            const day = date.getDate();
            const month = this.$root.$t('months.' + (date.getMonth() + 1), 2).toLowerCase();
            const year = date.getFullYear();
            return day + ' ' + month + ' ' + year + '.';
        },
        ...mapState({
            user: state => state.users.user,
            darkMode: state => state.darkMode
        })
    },
    data() {
        return {
            name: null,
            isLoggedIn: false,
        }
    },
    setup () {
        const store = useStore()
    },
    mounted() {
        this.$store.dispatch('users/getUserBySession', this.$router)
    },
    components: {
        Sidebar, Swlang, Pagetitle, Breadcrumbs, DayNightSwitcher, Messages, Fullscreen, ToggleSidebar
    },
    methods: {
        logout(e) {
            e.preventDefault()
            this.$axios.get('/sanctum/csrf-cookie').then(response => {
                this.$axios.post('/api/logout').then(response => {
                    if (response.data.success) {
                        this.isLoggedIn = false;
                        window.location.href = "/"
                    } else {
                        console.log(response)
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                    })
            })
        },
        testapi(e) {
            e.preventDefault()
            //this.$axios.get('/sanctum/csrf-cookie').then(response => {
            this.$axios.get('/api/testapi').then(response => {
                if (response.data.success) {
                    console.log('testapi Ok!')
                    console.log(response)
                } else {
                    console.log('testapi Fatal!')
                    console.log(response)
                }
            })
                .catch(function (error) {
                    console.log(error);
                })
            //})
        },
        sidebarClose() {
            this.$store.commit('toggleSidebar', false);
        },
    },
    beforeRouteEnter(to, from, next) {
        if (!window.Laravel.isLoggedIn) {
            window.location.href = "/"
        }
        next()
    }
}
</script>

<style scoped>
.logo {
    margin-right: 10px;
    max-width: 35px;
    margin-left: 5px;
}

@media (min-width: 1024px) {
    #sample {
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 1000px;
    }
}

.swlang {
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 10px;
}

.container-fluid{
    padding-left: 1rem;
}

</style>
