<template>

    <nav class="mt-2">

        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <router-link
                v-for="link in links"
                :to="{ name: link.name }"
                custom
                v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
                <li class="nav-item"
                    :class="{ 'menu-open': link.isOpen, 'menu-is-opening': link.isOpen }"
                    v-if="link.childs !== undefined && link.childs.length"
                >
                    <a href="#"
                        class="nav-link"
                        :class="{ active: link.isActive }"
                    >
                        <i :class="'nav-icon ' + link.ico" aria-hidden="true"></i>
                        <p> {{ ' ' + $t('sidebarmenu.' + link.title) + ' ' }} <i class="right fa fa-angle-left"></i></p>
                    </a>
                    <ul class="nav nav-treeview">
                        <router-link
                            v-for="child in link.childs"
                            :to="{ name: child.name }"
                            custom
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                        >
                            <li class="nav-item">
                                <a  class="nav-link"
                                    :class="{ active: isActive }"
                                    :href="href"
                                    @click="navigate"
                                >
                                    <i :class="'ms-sm-2 nav-icon ' + child.ico" aria-hidden="true"></i>
                                    <p> {{ $t('sidebarmenu.' + child.title) }} </p>
                                    <a v-if="child.badge" :class="child.badge.classes" href="#" class="">{{ child.badge.text }}</a>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </li>
                <li class="nav-item" v-else>
                    <a  class="nav-link"
                        :class="{ active: isActive }"
                        :href="href"
                        @click="navigate"
                    >
                        <i :class="'nav-icon ' + link.ico" aria-hidden="true"></i>
                        <p> {{ ' ' + $t('sidebarmenu.' + link.title) + ' ' }} </p>
                    </a>
                </li>
            </router-link>
            <li class="nav-item">
                <a href="#" class="nav-link" @click="logout">
                    <i class="fa fa-sign-out nav-icon" aria-hidden="true"></i>
                    <p>Logout</p>
                </a>
            </li>
        </ul>

    </nav>

  </template>

  <script>

import { useStore, mapState } from 'vuex'

  export default {
    props: ['value'],
    data: () => ({
      links: [
        {
            name: 'dashboard',
            ico: 'fa fa-home',
            title: 'dashboard'
        },
        {
            name: 'dashboard.tourists',
            ico: 'fa fa fa-users',
            title: 'tourists_main',
            childs: [
                {
                    name: 'dashboard.reservations',
                    ico: 'fa fa-id-card',
                    title: 'reservations',
                },
                {
                    name: 'dashboard.tourists',
                    ico: 'fa fa-users',
                    title: 'tourists'
                },
                {
                    name: 'dashboard.payments',
                    ico: 'fa fa-credit-card',
                    title: 'payments'
                },
                {
                    name: 'dashboard.pricenegotiation',
                    ico: 'fa fa-comments',
                    title: 'price_negotiation'
                }
            ]
        },
        {
            name: 'dashboard.hotels',
            title: 'hotels',
            ico: 'fa fa-university',
            childs: [
                {
                    name: 'dashboard.hotels',
                    ico: 'fa fa-university',
                    title: 'hotels_info'
                },
                {
                    name: 'dashboard.allocation',
                    ico: 'fa fa-th',
                    title: 'allocation',
                    badge: {
                        classes: "right badge bg-danger text-light",
                        text: "New"
                    }
                },
            ]
        },
        {
            name: 'dashboard.subscription',
            title: 'subscription',
            ico: 'fa fa-paper-plane',
        },
        /*
        {
            name: 'dashboard.devexamples',
            ico: 'fa fa-cog',
            title: 'devexamples',
            childs: [
                {
                    name: 'dashboard.devexamples.testapi',
                    ico: 'fa fa-code',
                    title: 'test_api'
                },
                {
                    name: 'dashboard.devexamples.dashboard1',
                    ico: 'fa fa-window-restore',
                    title: 'dashboard1',
                },
                {
                    name: 'dashboard.devexamples.dashboard2',
                    ico: 'fa fa-window-restore',
                    title: 'dashboard2',
                }
            ]
        }
        */
      ],
      sidebarElementId: 'sidebar-overlay'
    }),
    methods: {
        logout(e) {
            this.$store.dispatch('users/logout', this.$router)
        },
        formatLinks(notOpen = false) {
            const links = this.links;
            const self = this;
            links.forEach((link, i) => {
                if(link.childs !== undefined && link.childs.length) {
                    const childs = link.childs;
                    let isOpen = false;
                    let isActive = false;
                    childs.forEach((child) => {
                        if(child.name == self.currentRouteName){
                            isOpen = true;
                            isActive = true;
                        }
                    });
                    if(!notOpen)
                        links[i].isOpen = isOpen;
                    links[i].isActive = isActive;
                }
            });
            this.links = links;
        },
        sidebarClose() {
            this.$store.commit('toggleSidebar', false);
        },
        getSidebarDisplay() {
            return window.getComputedStyle(document.getElementById(this.sidebarElementId)).display;
        }
    },
    computed: {
        ...mapState({
            openSidebar: state => state.openSidebar
        }),
        currentRouteName() {
            return this.$route.name;
        },
        currentRoutePath() {
            return this.$route.path
        }
    },
    watch:{
        $route (to, from){
            this.formatLinks(true);
            if((to.name != from.name) && this.getSidebarDisplay() != 'none')
                this.sidebarClose();
        }
    },
    mounted() {
        this.formatLinks();
    }

  }
  </script>

<style>
@media (max-width: 1050px){
  .sidebar-open #sidebar-overlay {
    display: block;
  }
}
</style>
