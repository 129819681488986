import en from './en.json'
import ru from './ru.json'

export const defaultLocale = 'en'
export const fallbackLocale = 'en'

/**
 * Подгружаемые словари
 */
export const languages = {
    en, ru
}

/**
 * Языки, доступные в селекторе языков
 */
export const langswlist = {
    en: "/img/flags/4x3/gb.svg",
    ru: "/img/flags/4x3/ru.svg"
}
