/**
 * User model
 */
export default class User {

    id = 0
    partner = 0
    name = ''
    email = ''
    roles = []
    permissions = []
    token = ""
    
    constructor(sourceObj=null){
        if (sourceObj) {
            if (sourceObj.id) this.id = sourceObj.id
            if (sourceObj.name) this.name = sourceObj.name
            if (sourceObj.email) this.email = sourceObj.email
            if (sourceObj.token) this.token = sourceObj.token
            if (sourceObj.partner) this.partner = sourceObj.partner

            if (sourceObj.roles) {
                this.roles = sourceObj.roles
            } else {
                this.roles = []
            }
            if (sourceObj.permissions) {
                this.permissions = sourceObj.permissions
            } else {
                this.permissions = []
            }
        }
    }

    isAuthUser() { 
        return this.id > 0
    }

    // TODO - вдальнейшем оптимизироват, внедрить break
    hasRole(role) {
        if (role.constructor === Array){
            role.reduce((result, value) => this.role.indexOf(value) >= 0, false)
        } else {
            return this.role.indexOf(permission) >= 0
        }
    }

    // TODO - вдальнейшем оптимизироват, внедрить break
    hasPermissionTo(permission) {
        if (permission.constructor === Array){
            permission.reduce((result, value) => this.permissions.indexOf(value) >= 0, false)
        } else {
            return this.permissions.indexOf(permission) >= 0
        }
    }

}
