<template>
  <a class="nav-link dropdown-toggle" @click="toggleWidget()">
      <i class="fa fa-bell-o" aria-hidden="true"></i>
      <span v-if="unreadMessagesCount" class="badge bg-warning navbar-badge">{{ unreadMessagesCount }}</span>
  </a>
  <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" :class="{ show: isWidgetOpen }" v-on-click-outside="closeWidget">
      <div class="dropdown-divider"></div>
      <template v-for="message in messages" v-if="showMessage === null">
          <a href="#" class="dropdown-item" :class="{ unread: !message.read }" @click="openMessage(message)">
              {{ message.text }}
          </a>
          <div class="dropdown-divider"></div>
      </template>
      <div class="message" v-else>
          <p class="dropdown-item">{{ showMessage.text }}</p>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" @click="showMessage = null"><i class="fa fa-arrow-left"></i> {{ $t('components.back') }}</a>
      </div>
  </div>
</template>
  
<script>
  import { vOnClickOutside } from '@vueuse/components'

  export default {
    data() {
      return {
        showMessage: null,
        isWidgetOpen: false,
        unreadMessagesCount: 2,
        messages: [
          { text: 'Пример сообщения 1', read: false },
          { text: 'Пример сообщения 2', read: true },
          { text: 'Пример сообщения 3', read: false }
        ],
        selectedMessageIndex: null
      };
    },
    directives: {
        onClickOutside: vOnClickOutside
    },
    methods: {
      toggleWidget() {
        this.isWidgetOpen = !this.isWidgetOpen;
      },
      closeWidget() {
        this.isWidgetOpen = false;
        this.selectedMessageIndex = null;
      },
      openMessage(message) {
        message.read = true;
        this.showMessage = message;
        this.updateUnreadMessagesCount();
      },
      updateUnreadMessagesCount() {
        this.unreadMessagesCount = this.messages.filter(message => !message.read).length;
      }
    }
  };
</script>
  
<style scoped>
  .unread {
    font-weight: bold;
  }
  .dropdown-menu{
    right: 20px;
    font-size: var(--bs-nav-link-font-size);
  }
</style>