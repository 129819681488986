import { createApp } from "vue"

require('./bootstrap')

import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'

import { languages, defaultLocale, fallbackLocale} from './lang'
import { createI18n, useI18n } from 'vue-i18n'
import VueCookies from 'vue3-cookies'
import Toast from "vue-toastification" //https://github.com/Maronato/vue-toastification https://habr.com/ru/companies/ruvds/articles/517316/ https://vue-toastification.maronato.dev/
//import toastsPlugin from "@/utils/toasts.plugin"

const messages = Object.assign(languages)
const i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: fallbackLocale,
    globalInjection: true,
    legacy: false,
    messages
})

//Убрать потом эту хрень куда-то чтоб не светила
Array.prototype.contains = function(obj) {
    var i = this.length;
    while (i--) {
        if (this[i] === obj) {
            return true;
        }
    }
    return false;
}

const app = createApp(App, {
    setup() {
        const {t} = useI18n()
        return {t}
    }
})
app.config.globalProperties.$axios = axios
app.use(router)
app.use(store)
app.use(i18n)
app.use(VueCookies)
app.use(Toast, {
    timeout: 10000,
    position: 'bottom-right',
    transition: "Vue-Toastification__bounce",
    maxToasts: 5,
    // newestOnTop: true,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.7,
    showCloseButtonOnHover: false,
    //hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
})
//app.use(toastsPlugin)
app.mount('#app')
